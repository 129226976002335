import _ from 'lodash';
import CustomStore from 'devextreme/data/custom_store';
import request from '../../api';
import { store } from '../../store';
import { adminGetAllBranches } from '../../actions';

const { dispatch, getState } = store;
const selectedBranchId = () => getState().adminBranches.selectedBranchId;

const generateParamsForRequest = (loadOptions, isMoreOptions) => {
    let params = '?';
    for (let i in loadOptions) {
        if (loadOptions[i])
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
    }
    if (isMoreOptions)
        return params;
    return params = params.slice(0, -1);
};


export const categoriesDataGrid = new CustomStore({
    key: 'idCategory',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/categories/fullinfo/branch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/category', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/category/update', { ...values, categoryId: key, OBBranchId: selectedBranchId() }),
    remove: async (key) => await request.delete('/admin/category', { data: { categoryId: key } })
});



export const adminsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admins/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/user/new', _.omit(values, 'code')),
    update: async (key, values) => await request.post('/admin/user/update', { ..._.omit(values, 'code'), userId: key }),
    remove: async (key) => await request.delete('/user', { data: { userId: key } })
});

export const employeesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { users: data, allCount: totalCount } } = await request.get(`/users/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/user/new', _.omit(values, 'code')),
    update: async (key, values) => await request.post('/admin/user/update', { ..._.omit(values, 'code'), userId: key }),
    remove: async (key) => await request.delete('/user', { data: { userId: key } })
});

export const rolesDataGrid = new CustomStore({
    key: 'roleId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/roles/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/access_control/roles/new', values),
    update: async (key, values) => await request.post('/access_control/roles/update', { ...values, roleId: key }),
    remove: async (key) => await request.delete('/access_control/role', { data: { roleId: key } })
});

export const roleResourcesDataGrid = (roleId) => new CustomStore({
    key: 'id',
    load: async () => {
        try {
            const { data } = await request.get(`/access_control/permissions/resources/fullinfo/role/${roleId}`);
            return { data, totalCount: Object.keys(data).length };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/access_control/role/resource', { resourceId: values.resource, roleId }),
    remove: async (key) => await request.delete('/access_control/role/resource', { data: { resourceId: key, roleId } })
});
roleResourcesDataGrid.reset = async (roleId, resources) => await request.post('/access_control/role/resources/reset', { roleId, resources });

export const branchesDataGrid = new CustomStore({
    key: 'idOBBranch',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/obbranch/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => {
        await request.post('/admin/obbranch/new', values);
        adminGetAllBranches()(dispatch, getState);
    },
    update: async (key, values) => {
        await request.post('/admin/obbranch/update', { ...values, OBBranchId: key });
        adminGetAllBranches()(dispatch, getState);
    },
    remove: async (key) => {
        await request.delete('/admin/obbranch', { data: { OBBranchId: key } });
        adminGetAllBranches()(dispatch, getState);
    }
});
branchesDataGrid.activate = async (values) => await request.post('/admin/obbranch/activate', values);


export const finCustomersSuppliersDataGrid = (type) => new CustomStore({
    key: 'idCustomer',
    load: async (loadOptions) => {
        try {
            if (type)
                loadOptions = { ...loadOptions, filter: loadOptions.filter ? [['type', '=', type], 'and', loadOptions.filter] : ['type', '=', type] };
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/customers/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount, summary: [totalCount] };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/customer', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/customer/update', { ...values, customerId: key }),
    remove: async (key) => await request.delete('/admin/aw/customer', { data: { customerId: key } })
});


export const regionsDataGrid = new CustomStore({
    key: 'regionId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/regions/fullinfo/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/region', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/region/update', { ...values, regionId: key }),
    remove: async (key) => await request.delete('/admin/region', { data: { regionId: key } })
});

export const countriesDataGrid = new CustomStore({
    key: 'countryId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/countries/fullinfo/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/country', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/country/update', { ...values, countryId: key }),
    remove: async (key) => await request.delete('/admin/country', { data: { countryId: key } })
});

export const citiesDataGrid = (countryId) => new CustomStore({
    key: 'cityId',
    load: async (loadOptions) => {
        try {
            let params = generateParamsForRequest(loadOptions, true);
            if (countryId)
                params += `countryId=${countryId}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/cities/fullinfo/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/city', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/city/update', { ...values, cityId: key }),
    remove: async (key) => await request.delete('/admin/city', { data: { cityId: key } })
});


export const branchSettingsDataGrid = new CustomStore({
    key: 'idSetting',
    load: async (loadOptions) => {
        try {
            let params = generateParamsForRequest(loadOptions, true);
            params += `OBBranchId=${selectedBranchId()}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/settings/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/setting', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/setting/update', { ...values, settingId: key }),
    remove: async (key) => await request.delete('/admin/setting', { data: { settingId: key } })
});

export const generalSettingsDataGrid = new CustomStore({
    key: 'idSetting',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/settings/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/setting', values),
    update: async (key, values) => await request.post('/admin/setting/update', { ...values, settingId: key }),
    remove: async (key) => await request.delete('/admin/setting', { data: { settingId: key } })
});

export const finSalesDealsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/sale-deals/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/sale-deal', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/sale-deal/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/sale-deal', { data: { id: key } })
});

export const finSalesContactsDataGrid = new CustomStore({
    key: 'idContact',
    load: async (loadOptions) => {
        try {
            loadOptions = { ...loadOptions, filter: ['type', '=', "SALE"] };
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/contacts/all/branch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/contact', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/contact/update', { ...values, contactId: key, OBBranchId: selectedBranchId() }),
    remove: async (key) => await request.delete('/admin/contacts', { data: { contactsIds: [key] } })
});

export const finSalesReservationsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/sale-reserve/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/sale-reserve', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/sale-reserve', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/sale-reserve', { data: { id: key } })
});