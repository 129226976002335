import React, { useState } from 'react';
import { Button, Dropdown, Menu, Header, Transition, Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import { signout } from '../../actions';
import { getHeaderBarData } from '../HeaderData';
import { Media } from '../../Media';
import onLangChange from '../../helpers/checkLang';
import { BranchSelector } from '..';

import avatarImage from '../../res/images/avatar.jpg';
import history from '../../history';
import getPaths from '../../Routers/PATHS';
const PATHS = getPaths();

const NavBar = (props) => {
    const { lang, setLang, t, user, signout } = props;
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const languageOptions = [
        { key: '1', text: t('Arabic'), value: 'ar', flag: 'sa' },
        { key: '2', text: t('English'), value: 'en', flag: 'gb' }
    ];

    const renderAuthButtons = () => {
        if (!user) return <>
            <Menu.Item>
                <Button style={{ margin: 3 }} as={Link} to='/signin' onClick={() => setShowMobileMenu(false)} content={t('SignIn').toUpperCase()} fluid positive circular />
            </Menu.Item>
            {/* <Menu.Item>
                <Button style={{ margin: 3 }} as={Link} to='/signup' onClick={() => setShowMobileMenu(false)} content={t('EnrollNow').toUpperCase()} fluid primary circular />
            </Menu.Item> */}
        </>;
        return <>
            <Menu.Item /*as={Link} to='/profile'*/>
                <Header as='h5' textAlign='center' inverted>
                    <Avatar src={user.coverImgUrl || avatarImage} round size='50' />
                    <Header.Content style={{ verticalAlign: 'middle', marginLeft: 5, marginRight: 10 }}>{user.firstName.toUpperCase()}</Header.Content>
                </Header>
            </Menu.Item>
            <Popup
                on='hover'
                flowing
                hoverable
                trigger={
                    <Menu.Item>
                        <Icon name='building' />
                        {t('Branch')}
                    </Menu.Item>
                }
            >
                <BranchSelector />
            </Popup>


            <Menu.Item onClick={signout}>
                <Icon name='sign-out' />
                {t('Logout')}
            </Menu.Item>


        </>;
    };

    const renderMobileNav = () => {
        return (
            <Media lessThan='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren &&
                            <Menu borderless fluid widths={1} stackable inverted color='brown' className={mediaClassNames} style={{ borderRadius: 0 }}>
                                <Menu.Item>
                                    <Header as='h1' content={t('OB')} onClick={() => history.push(PATHS.HOME.URL)} style={{ alignSelf: 'center', padding: 0, margin: 0, cursor: 'pointer' }} inverted className='animationRotate' />
                                </Menu.Item>

                                <Menu.Item style={{ backgroundColor: 'brown' }} icon={showMobileMenu ? 'times' : 'bars'} onClick={() => setShowMobileMenu(!showMobileMenu)} />

                                <Transition.Group animation='slide down' duration={200}>

                                    {
                                        showMobileMenu && getHeaderBarData().map(item => <Menu.Item
                                            key={item.link}
                                            name={item.name}
                                            as={Link}
                                            to={item.link}
                                            onClick={() => setShowMobileMenu(false)}
                                        />)
                                    }

                                </Transition.Group>
                                {showMobileMenu && <Dropdown
                                    options={languageOptions}
                                    defaultValue={lang}
                                    onChange={(e, { value }) => {
                                        onLangChange(value, setLang);
                                        window.location.reload();
                                    }}
                                    inline
                                    item
                                    pointing
                                    fluid
                                />}
                                {showMobileMenu && renderAuthButtons()}
                            </Menu>
                        );
                    }
                }
            </Media>
        );
    };

    const renderComputerNav = () => {
        return (
            <Media greaterThanOrEqual='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren &&
                            <Menu borderless  color='brown' fluid stackable widths={getHeaderBarData().length + 7} inverted className={mediaClassNames} style={{ borderRadius: 0 }}>
                                <Header as='h1' content={t('OB')} onClick={() => history.push(PATHS.HOME.URL)} style={{ alignSelf: 'center', padding: 0, margin: 0, cursor: 'pointer' }} inverted className='animationRotate' />
                                {
                                    getHeaderBarData().map(item => <Menu.Item
                                        key={item.link}
                                        name={item.name}
                                        as={Link}
                                        to={item.link}
                                    />)
                                }

                                <Dropdown
                                    options={languageOptions}
                                    defaultValue={lang}
                                    onChange={(e, { value }) => {
                                        onLangChange(value, setLang);
                                        window.location.reload();
                                    }}
                                    inline
                                    item
                                    pointing
                                    fluid
                                />
                                {renderAuthButtons()}
                            </Menu>
                        );
                    }}
            </Media>
        );
    };

    const renderMenu = () => {
        return (
            <>
                {renderComputerNav()}
                {renderMobileNav()}
            </>
        );
    };

    return (
        <div>
            <Helmet>
                <title>{`Mapping Sales`}</title>
                <meta property='og:title' content={`Mapping Sales`} />
                <meta property='og:url' content='https://www.mappingsales.com' />
                <meta property='og:image' content='https://i.imgur.com/2uKtPLp.png' />
                <meta property="og:image:width" content="1792" />
                <meta property="og:image:height" content="963" />
                <meta property="og:image:alt" content="Mapping Sales Logo" />
                <meta property="og:type" content="website" />
                <meta name="og:description" content="Mapping Sales official site." />
            </Helmet>
            {renderMenu()}
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    const { user } = auth;
    return { user, };
};

export default withTranslation()(connect(mapStateToProps, { signout })(NavBar));
