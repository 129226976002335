import React from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { GetSideBarData } from '../SidebarData';

const SideBarNav = ({ user }) => {
    const data = GetSideBarData(user.isAdmin) || [];
    if (!user || !data.length)
        return null;
    return (
        <Menu borderless inverted color='brown' stackable widths={data.length} style={{ borderRadius: 0 }} >
            {data.filter(item => item.permission).map(item => <Dropdown
                simple
                item
                key={item.text}
                text={item.text}
                icon={item.icon}
                style={{ display: 'flex', justifyContent: 'space-evenly' }}
                options={item.subs.filter(sub => sub.permission).map(sub => ({ ...sub, permission: 1, active: false }))}
            />)}

        </Menu>
    );
};

export default SideBarNav;