import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RequirePermission, RequireNoAuth } from '../middlewares/HOC';
import getPaths from './PATHS';

import {
    LandginHome,
    Signin, ResetPassword, Activation,

    AdminDashboard, AdminAdmins, AdminRoles, AdminCategories, AdminRegions, AdminCountries,
    AdminBranches, AdminSettingsGeneral, AdminManualGuide,

    AdminSalesCustomers, AdminSalesDeals, AdminSalesContacts, AdminSalesReservations,
} from '../pages';

const Router = ({ selectedBranchId }) => {
    const PATHS = getPaths(selectedBranchId);
    return <Switch>
        {/* Basic Routes */}
        <Route path={PATHS.HOME.URL} exact component={LandginHome} />
        <Route path={PATHS.RESET_PASSWORD.URL} exact component={ResetPassword} />
        <Route path={PATHS.ACTIVATION.URL} exact component={Activation} />

        {/* <Route path="/signup" exact component={RequireNoAuth(Signup)} /> */}
        <Route path={PATHS.SIGNIN.URL} exact component={RequireNoAuth(Signin)} />
        {/* <Route path="/profile" exact component={Profile} /> */}



        <Route path={PATHS.ADMIN_DASHBOARD.URL} exact component={RequirePermission(AdminDashboard, PATHS.ADMIN_DASHBOARD.RESOURCES)} />
        <Route path={PATHS.ADMIN_BRANCHES.URL} exact component={RequirePermission(AdminBranches, PATHS.ADMIN_BRANCHES.RESOURCES)} />
        <Route path={PATHS.ADMIN_EMPLOYEES.URL} exact component={RequirePermission(AdminAdmins, PATHS.ADMIN_EMPLOYEES.RESOURCES)} />
        <Route path={PATHS.ADMIN_ROLES.URL} exact component={RequirePermission(AdminRoles, PATHS.ADMIN_ROLES.RESOURCES)} />

        <Route path={PATHS.ADMIN_SETTINGS_GENERAL.URL} exact component={RequirePermission(AdminSettingsGeneral, PATHS.ADMIN_SETTINGS_GENERAL.RESOURCES)} />
        <Route path={PATHS.ADMIN_COUNTRIES.URL} exact component={RequirePermission(AdminCountries, PATHS.ADMIN_COUNTRIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_REGIONS.URL} exact component={RequirePermission(AdminRegions, PATHS.ADMIN_REGIONS.RESOURCES)} />
        <Route path={PATHS.ADMIN_CATEGORIES.URL} exact component={RequirePermission(AdminCategories, PATHS.ADMIN_CATEGORIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_MANAUAL_GUIDE.URL} exact component={AdminManualGuide} />

        <Route path={PATHS.ADMIN_SALES_CUSTOMERS.URL} exact component={RequirePermission(AdminSalesCustomers, PATHS.ADMIN_SALES_CUSTOMERS.RESOURCES, PATHS.ADMIN_SALES_CUSTOMERS.PERMISSIONS, 'OR')} />
        <Route path={PATHS.ADMIN_SALES_DEALS.URL} exact component={RequirePermission(AdminSalesDeals, PATHS.ADMIN_SALES_DEALS.RESOURCES)} />
        <Route path={PATHS.ADMIN_SALES_CONTACTS.URL} exact component={RequirePermission(AdminSalesContacts, PATHS.ADMIN_SALES_CONTACTS.RESOURCES)} />
        <Route path={PATHS.ADMIN_SALES_RESERVATIONS.URL} exact component={RequirePermission(AdminSalesReservations, PATHS.ADMIN_SALES_RESERVATIONS.RESOURCES)} />


        {/* {renderRoutes(user.roleId)} */}

        {/* Invalid Routes */}
        <Route>
            <Route path="*" exact component={LandginHome} />
            <Redirect from='*' to='/' />
        </Route>

    </Switch>;
};

export default Router;
