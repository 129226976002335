import { lazy } from 'react';

// LANDING SECTION
const LandginHome = lazy(() => import('./landing section/Home'));
const LandingContactUs = lazy(() => import('./landing section/ContactUs'));
const Signup = lazy(() => import('./landing section/Auths/Signup'));
const Signin = lazy(() => import('./landing section/Auths/Signin'));
const ResetPassword = lazy(() => import('./landing section/Auths/ResetPassword'));
const Activation = lazy(() => import('./landing section/Auths/Activation'));
const Profile = lazy(() => import('./landing section/Auths/Profile'));

// USER SECTION
const UserDashboard = lazy(() => import('./user section/Dashboard'));


// ADMIN SECTION
const AdminDashboard = lazy(() => import('./admin section/Dashboard'));
const AdminAdmins = lazy(() => import('./admin section/Admins'));
const AdminRoles = lazy(() => import('./admin section/Roles/Roles'));
const AdminBranches = lazy(() => import('./admin section/Branches/Branches'));

const AdminSettingsGeneral = lazy(() => import('./admin section/Settings/GeneralSettings'));
const AdminCountries = lazy(() => import('./admin section/Settings/Countries/Countries'));
const AdminRegions = lazy(() => import('./admin section/Settings/Countries/Regions'));
const AdminCategories = lazy(() => import('./admin section/Settings/Categories'));
const AdminManualGuide = lazy(() => import('./admin section/Help/ManualGuide'));



// ADMIN Sales SECTION 
const AdminSalesCustomers = lazy(() => import('./admin section/Sales/Customers'));
const AdminSalesDeals = lazy(() => import('./admin section/Sales/Deals'));
const AdminSalesContacts = lazy(() => import('./admin section/Sales/Contacts'));
const AdminSalesReservations = lazy(() => import('./admin section/Sales/Reservations'));

export {
    LandginHome,
    Signup, Signin, ResetPassword, Profile, Activation, LandingContactUs,

    UserDashboard,

    AdminDashboard, AdminAdmins, AdminRoles, AdminCategories, AdminRegions, AdminCountries,
    AdminBranches, AdminSettingsGeneral, AdminManualGuide,

    AdminSalesCustomers, AdminSalesDeals, AdminSalesContacts, AdminSalesReservations,
};