import { combineReducers } from 'redux';

import SigninReducer from './landing section/SigninReducer';
import SignupReducer from './landing section/SignupReducer';
import ForgotPasswordReducer from './landing section/ForgotPasswordReducer';
import ResetPasswordReducer from './landing section/ResetPasswordReducer';
import ActicationCodeReducer from './landing section/ActivationReducer';
import ContactUsReducer from './landing section/ContactUsReducer';
import CountriesReducer from './landing section/CountriesReducer';
import ProfileReducer from './landing section/ProfileReducer';

import AdminBranchesReducer from './admin section/BranchesReducer';


export default combineReducers({
    auth: SigninReducer,
    signup: SignupReducer,
    forgot: ForgotPasswordReducer,
    resetPassword: ResetPasswordReducer,
    activation: ActicationCodeReducer,
    contactus: ContactUsReducer,
    countries: CountriesReducer,
    profile: ProfileReducer,

    adminBranches: AdminBranchesReducer,
});