import CustomStore from 'devextreme/data/custom_store';
import i18next from 'i18next';
import request from '../../api';
import { Consts } from '../../res/Consts';
import { store } from '../../store';

const { getState } = store;
const selectedBranchId = () => getState().adminBranches.selectedBranchId;

const generateParamsForRequest = (loadOptions, isMoreOptions) => {
    let params = '?';
    for (let i in loadOptions) {
        if (loadOptions[i])
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
    }
    if (isMoreOptions)
        return params;
    return params = params.slice(0, -1);
};

export const rolesDataLookup = new CustomStore({
    key: 'roleId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/roles/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const employeesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admins/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const categoriesDataLookup = new CustomStore({
    key: 'idCategory',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/categories/fullinfo/branch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const allResourcesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/resources/fullinfo${params}`);
            const resourcesWithDefaultPermissions = data.map(r => ({ ...r, permissions: { ADD: false, EDIT: false, DELETE: false, READ: false, EXPORT: false } }));
            return { data: resourcesWithDefaultPermissions, totalCount };
        } catch (err) {
            return err;
        }
    },
});



export const permissionsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data } = await request.get(`/access_control/permissions/fullinfo${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});


export const finCustomersSuppliersDataLookup = new CustomStore({
    key: 'idCustomer',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/customers/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finCustomersTypesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async () => {
        try {
            const data = [
                { id: 1, type: Consts.CUSTOMERS_TYPES.SUPPLIER, value: i18next.t('Supplier') },
                { id: 2, type: Consts.CUSTOMERS_TYPES.CUSTOMER, value: i18next.t('Customer') }
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});


export const regionsDataLookup = new CustomStore({
    key: 'regionId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount } } = await request.get(`/admin/regions/active/${selectedBranchId()}${params}`);
            return { data, totalCount: allCount };
        } catch (err) {
            return err;
        }
    },
});

export const countriesDataLookup = new CustomStore({
    key: 'countryId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { countries: data, allCount } } = await request.get(`/admin/countries/active/${selectedBranchId()}${params}`);
            return { data, totalCount: allCount };
        } catch (err) {
            return err;
        }
    },
});

export const citiesDataLookup = new CustomStore({
    key: 'idCity',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount } } = await request.get(`/admin/cities/active/${selectedBranchId()}${params}`);
            return { data, totalCount: allCount };
        } catch (err) {
            return err;
        }
    },
});

export const finSaleContactsDataLookup = new CustomStore({
    key: 'idContact',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions, true);
            const { data: { data } } = await request.get(`/admin/contacts/all/branch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});