export const Consts = {
    CUSTOMERS_TYPES: {
        SUPPLIER: "SUPPLIER",
        CUSTOMER: "END_CONSUMER",
        SALE_CUSTOMER: "SALE_CUSTOMER"
    },
    CATEGORIES_TYPES: {
        CUSTOMERS: "CUSTOMERS",
    },
    GENERAL_SETTINGS_TYPES: {
        GENERAL_WEBSITE_GUID_DOC_EN: "GENERAL_WEBSITE_GUID_DOC_EN",
        GENERAL_WEBSITE_GUID_DOC_AR: "GENERAL_WEBSITE_GUID_DOC_AR",
    },
    BRANCH_SETTINGS_TYPES: {
        GLOBAL_OFFER_PRICE_MARGIN: 'GLOBAL_OFFER_PRICE_MARGIN',
        GLOBAL_OFFER_PRICE_MARGIN_CURRUNCY: 'GLOBAL_OFFER_PRICE_MARGIN_CURRUNCY',
        OFFER_MSG_CONSUMER_TEMPLATE_EN: "OFFER_MSG_CONSUMER_TEMPLATE_EN",
        REQ_OFFER_MSG_SUPPLIER_TEMPLATE_EN: "REQ_OFFER_MSG_SUPPLIER_TEMPLATE_EN",
        MATERIAL_MSG_CONSUMER_TEMPLATE_EN: "MATERIAL_MSG_CONSUMER_TEMPLATE_EN",
    },
    SETTINGS_TYPES_INPUT: {
        SIMPLE: "SIMPLE",
        LOOKUP: "LOOKUP",
        UPLOAD_FILE: "UPLOAD_FILE",
        MSG_TEMPLATE: 'MSG_TEMPLATE'
    },
    FIN_ACCOUNTS_TYPES: {
        NORMAL: "NORMAL",
        FINAL: "FINAL",
    },
    FIN_ACCOUNTS_NATURES: {
        DEBT: "DEBT",
        CREDIT: "CREDIT",
        DEBT_CREDIT: "DEBT_CREDIT"
    },
    FIN_WAREHOUSES_TYPES: {
        NORMAL: "NORMAL",
    },
    FIN_BILL_TYPES_KINDS: {
        IN: "IN",
        OUT: "OUT",
        PURCHASE: "PURCHASE",
        SALE: "SALE",
        PURCHASE_RETURN: "PURCHASE_RETURN",
        SALE_RETURN: "SALE_RETURN",
    },
    FIN_BILLS_PAY_METHODS: {
        CASH: "CASH",
        DELAYED: "DELAYED"
    },
    FIN_SECURITY_DEGREES: {
        LOW: "LOW",
        MID: "MID",
        HIGH: "HIGH",
    },
    FIN_PROFORMA_INVOICE_TYPES: {
        EXPORT: "EXPORT",
        LOCAL: "LOCAL"
    }

};