import React, { createRef, useState } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import DataGrid, { Column, Editing, RequiredRule, Lookup, MasterDetail } from 'devextreme-react/ui/data-grid';
import { Form, SimpleItem, EmailRule, ButtonItem } from 'devextreme-react/ui/form';
import LoadIndicator from 'devextreme-react/ui/load-indicator';
import { finSalesContactsDataGrid } from '../../Data Stores';
import { ErrorSegment } from '..';
import LoadingGifImage from '../../res/images/loading.gif';

const SuppliersContactsGrid = ({ t, data, contacts }) => {
    const isArabic = i18next.language === 'ar';
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [supplierContacts] = useState(data?.value || []);

    const gridRef = createRef();
    const handleDataStore = (DS) => {
        const filterd = checkAllEmailEntered(DS);
        data.setValue(filterd.map(c => c.idContact));
    };

    const selectionChanged = (e) => {
        e.component.collapseAll(-1);
        e.component.expandRow(e.currentSelectedRowKeys[0]);
    };

    const updateContactInfo = async (newInfo) => {
        setLoading(true);
        try {
            await finSalesContactsDataGrid.update(newInfo.idContact, newInfo);
            handleDataStore(supplierContacts);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const checkAllEmailEntered = (DS) => {
        const filterd = contacts.filter(c => DS.map(c => c.contactId).includes(c.idContact));
        const withEmail = filterd.filter(c => c.email);
        return withEmail;
    };

    const renderDetails = (data) => {
        const c = contacts.find(cc => cc.idContact === data.contactId);
        if (c) return (
            <Segment basic>
                <form onSubmit={(e) => { e.preventDefault(); updateContactInfo(c); }}>
                    <Form formData={c} colCount={2}>
                        <SimpleItem dataField="email" label={{ text: t('Email') }}>
                            <EmailRule message={t("EmailError")} />
                            <RequiredRule />
                        </SimpleItem>
                        <SimpleItem dataField="mobile" label={{ text: t('Mobile') }} editorOptions={{ readOnly: true }} />
                        {loading ? <SimpleItem >
                            <LoadIndicator indicatorSrc={LoadingGifImage} />
                        </SimpleItem>
                            : <ButtonItem horizontalAlignment={isArabic ? 'right' : 'left'} buttonOptions={{ type: 'default', text: t('Update'), useSubmitBehavior: true }} />
                        }
                    </Form>
                </form>
            </Segment>
        );
    };

    if (error) return <ErrorSegment />;

    return (
        <>
            <Header as='h5' color='red' content={t('ContactEmailRequired')} />
            <DataGrid
                ref={gridRef}
                dataSource={supplierContacts}
                showBorders
                showColumnLines
                columnHidingEnabled
                wordWrapEnabled
                columnAutoWidth
                onSelectionChanged={selectionChanged}
                onRowUpdated={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
                onRowInserted={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
                onRowRemoved={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
            >
                <Editing
                    mode='row'
                    useIcons
                    allowUpdating
                    allowAdding
                    allowDeleting
                    refreshMode='reshape'
                />
                <Column dataField='contactId' caption={t('Contact')} alignment='center'>
                    <Lookup
                        dataSource={{
                            store: contacts,
                            paginate: true,
                            pageSize: 50,
                        }}
                        displayExpr={({ displayName, type }) => `${displayName} / [${type}]`}
                        valueExpr='idContact'
                    />
                    <RequiredRule />
                </Column>

                <MasterDetail enabled render={({ data }) => renderDetails(data)} />
            </DataGrid>
        </>
    );
};

export default withTranslation()(SuppliersContactsGrid);
