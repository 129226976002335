import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getPaths from '../Routers/PATHS';
import checkResources from '../helpers/checkResources';

export const GetSideBarData = (isAdmin) => {
    const { t } = useTranslation();
    const PATHS = getPaths();
    const administrativeResources = [...PATHS.ADMIN_ROLES.RESOURCES, ...PATHS.ADMIN_BRANCHES.RESOURCES, ...PATHS.ADMIN_EMPLOYEES.RESOURCES];
    const salesResources = [...PATHS.ADMIN_SALES_CUSTOMERS.RESOURCES, ...PATHS.ADMIN_SALES_CONTACTS.RESOURCES,];
    const settingsResources = [...PATHS.ADMIN_SETTINGS_GENERAL.RESOURCES];
    const utilitiesResources = [...PATHS.ADMIN_CATEGORIES.RESOURCES, ...PATHS.ADMIN_REGIONS.RESOURCES];

    if (isAdmin) return [
        {
            permission: checkResources(administrativeResources), text: t('Administrative'), icon: 'chess king', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_ROLES.RESOURCES), text: t('Roles'), icon: 'key', to: PATHS.ADMIN_ROLES.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_BRANCHES.RESOURCES), text: t('Branches'), icon: 'building', to: PATHS.ADMIN_BRANCHES.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_EMPLOYEES.RESOURCES), text: t('Employees'), icon: 'spy', to: PATHS.ADMIN_EMPLOYEES.URL },
            ]
        },

        {
            permission: checkResources(salesResources), text: t('Management'), icon: 'desktop', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_SALES_CONTACTS.RESOURCES), text: t('Contacts'), icon: 'address book', to: PATHS.ADMIN_SALES_CONTACTS.URL },
                { key: 2, as: Link, value: 2, permission: (checkResources(PATHS.ADMIN_SALES_CUSTOMERS.RESOURCES) || checkResources(PATHS.ADMIN_SALES_CUSTOMERS.RESOURCES, 'ADD')), text: `${t('Customers')}`, icon: 'users', to: PATHS.ADMIN_SALES_CUSTOMERS.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_SALES_DEALS.RESOURCES), text: t('Deals'), icon: 'handshake', to: PATHS.ADMIN_SALES_DEALS.URL },
                { key: 4, as: Link, value: 4, permission: checkResources(PATHS.ADMIN_SALES_RESERVATIONS.RESOURCES), text: t('Reservations'), icon: 'hand paper', to: PATHS.ADMIN_SALES_RESERVATIONS.URL },

            ]
        },

        {
            permission: checkResources(utilitiesResources), portals: ['GENERAL'], text: t('Utilities'), icon: 'configure', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_REGIONS.RESOURCES), text: t('Regions'), icon: 'globe', to: PATHS.ADMIN_REGIONS.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_CATEGORIES.RESOURCES), text: t('Categories'), icon: 'th', to: PATHS.ADMIN_CATEGORIES.URL },
            ]
        },

        {
            permission: checkResources(settingsResources), text: t('Settings'), icon: 'settings', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_SETTINGS_GENERAL.RESOURCES), text: t('GeneralSettings'), icon: 'setting', to: PATHS.ADMIN_SETTINGS_GENERAL.URL },
            ]
        },
        {
            permission: true, text: t('Help'), icon: 'help circle', subs: [
                { key: 1, as: Link, value: 1, permission: true, text: t('ManualGuide'), icon: 'file pdf', to: PATHS.ADMIN_MANAUAL_GUIDE.URL },
            ]
        },
    ];
};