
import { store } from '../store';
const { getState } = store;
const selectedBranchID = () => getState().adminBranches.selectedBranchId;

export default (selectedBranchId = selectedBranchID()) => ({
    HOME: { URL: '/', RESOURCES: [], PERMISSIONS: [] },
    RESET_PASSWORD: { URL: '/resetpassword/:resetCode', RESOURCES: [], PERMISSIONS: [] },
    ACTIVATION: { URL: '/activation', RESOURCES: [], PERMISSIONS: [] },
    SIGNIN: { URL: '/signin', RESOURCES: [], PERMISSIONS: [] },
    ADMIN_DASHBOARD: { URL: '/admin/dashboard', RESOURCES: ['ADMINS_CONTROL_PANEL', `OB_BRANCH_${selectedBranchId}_ADMINS_CONTROL_PANEL`], PERMISSIONS: [] },
    ADMIN_BRANCHES: { URL: '/admin/branches', RESOURCES: ['OB_BRANCHES', `OB_BRANCH_${selectedBranchId}`], PERMISSIONS: [] },
    ADMIN_EMPLOYEES: { URL: '/admin/admins', RESOURCES: ['USERS', `OB_BRANCH_${selectedBranchId}_USERS`], PERMISSIONS: [] },
    ADMIN_ROLES: { URL: '/admin/roles', RESOURCES: ['ACCESS_CONTROL', `OB_BRANCH_${selectedBranchId}_ACCESS_CONTROL`], PERMISSIONS: [] },
    ADMIN_SETTINGS_GENERAL: { URL: '/admin/settings/general', RESOURCES: [`SETTINGS`], PERMISSIONS: [] },
    ADMIN_COUNTRIES: { URL: '/admin/settings/countries', RESOURCES: [`OB_BRANCH_${selectedBranchId}_COUNTRIES`], PERMISSIONS: [] },
    ADMIN_REGIONS: { URL: '/admin/settings/regions', RESOURCES: [`OB_BRANCH_${selectedBranchId}_COUNTRIES`], PERMISSIONS: [] },
    ADMIN_CATEGORIES: { URL: '/admin/categories', RESOURCES: [`OB_BRANCH_${selectedBranchId}_CATEGORIES`], PERMISSIONS: [] },
    ADMIN_MANAUAL_GUIDE: { URL: '/admin/help/manualGuide', RESOURCES: [], PERMISSIONS: [] },

    ADMIN_SALES_CUSTOMERS: { URL: '/admin/financial/sales/customers', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CUSTOMERS`], PERMISSIONS: ['READ', 'ADD'] },
    ADMIN_SALES_DEALS: { URL: '/admin/financial/sales/deals', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CUSTOMERS`], PERMISSIONS: ['READ', 'ADD'] },
    ADMIN_SALES_CONTACTS: { URL: '/admin/financial/sales/contacts', RESOURCES: [`OB_BRANCH_${selectedBranchId}_CONTACTS`], PERMISSIONS: [] },
    ADMIN_SALES_RESERVATIONS: { URL: '/admin/financial/sales/reservations', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CUSTOMERS`], PERMISSIONS: [] },

});